<template>
  <form @submit.prevent="doRegister">
    <v-card-text class="my-0 py-0">
      <v-text-field
        prepend-icon="mdi-account"
        :label="$lang('AUTH:REGISTER:INPUT_TEXT_USERNAME-LABEL')"
        :hint="$lang('AUTH:REGISTER:INPUT_TEXT_USERNAME-HINT')"
        v-model="user.username"
        type="text"
        :error-messages="errors && errors.username ? errors.username : []"
      ></v-text-field>

      <v-text-field
        prepend-icon="mdi-email"
        :label="$lang('AUTH:REGISTER:INPUT_TEXT_EMAIL-LABEL')"
        :hint="$lang('AUTH:REGISTER:INPUT_TEXT_EMAIL-HINT')"
        v-model="user.email"
        type="email"
        :rules="[rules.required, rules.email]"
        :error-messages="errors && errors.email ? errors.email : []"
      ></v-text-field>

      <v-text-field
        prepend-icon="mdi-lock"
        @click:append="showPassword = !showPassword"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        v-model="user.password"
        :rules="[rules.required, rules.min]"
        :error-messages="errors && errors.password ? errors.password : []"
        :label="$lang('AUTH:REGISTER:INPUT_TEXT_PASSWORD-LABEL')"
        :hint="$lang('AUTH:REGISTER:INPUT_TEXT_PASSWORD-HINT')"
        counter
      ></v-text-field>
    </v-card-text>
    <v-card-actions class="my-0 py-0">
      <v-spacer></v-spacer>
      <v-btn block class="blue accent-4" dark type="submit">
        <v-icon left>mdi-account-plus</v-icon>
        {{ $lang("Create Account") }}
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </form>
</template>

<script>
import bus from "@/bus";
import { mapActions } from "vuex";
import localforage from "@/plugins/localforage";
import { isEmpty } from "lodash";
export default {
  name: "register-with-email",
  components: {},
  data() {
    return {
      user: {
        username: "",
        email: "",
        password: "",
        password_confirm: "",
      },
      showSettings: false,
      showPassword: false,
      rules: {
        required: (value) => !!value || this.$lang("Required"),
        min: (v) => {
          return (
            v.length >= 6 ||
            this.$lang("AUTH:REGISTER:INPUT_TEXT_PASSWORD-HINT")
          );
        },
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  computed: {
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  methods: {
    ...mapActions({
      register: "auth/register",
    }),
    doRegister() {
      this.register({ user: this.user, context: this })
        .then((res) => {
          // this.$router.replace({ name: "verify-email" });
          this.afterSuccess();
        })
        .catch(() => {});
    },
    afterSuccess(verified) {
      console.log("success");
      return localforage
        .getItem("intended")
        .then((intended) => {
          if (isEmpty(intended)) {
            this.$router.replace({ name: "home" });
            return;
          }

          if (verified) {
            //remove this key if user is varified
            localforage.removeItem("intended");
          }

          this.$router.replace(intended);
        })
        .catch((err) => {
          console.error(err);
          this.$router.replace({ name: "home" });
        });
    },
  },
  mounted() {},
  created() {
    console.log(this.$route.meta);
    bus.$on("contextButtonClicked", () => {
      this.showSettings = !this.showSettings;
    });
  },
  destroyed() {},
};
</script>


