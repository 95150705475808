<template>
  <form @submit.prevent="doRegister">
    <v-card-text class="my-0 py-0">
      <v-text-field
        prepend-icon="mdi-phone"
        prefix="+91-"
        :label="$lang('MOBILENUMBER')"
        :hint="$lang('10 Digit mobile number without country code.')"
        v-model="user.mobile"
        type="number"
        :error-messages="errors && errors.mobile ? errors.mobile : []"
        :rules="[rules.required, rules.max]"
      ></v-text-field>

      <v-text-field
        prepend-icon="mdi-lock"
        @click:append="showPassword = !showPassword"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        v-model="user.password"
        :rules="[rules.required, rules.min]"
        :error-messages="errors && errors.password ? errors.password : []"
        :label="$lang('AUTH:REGISTER:INPUT_TEXT_PASSWORD-LABEL')"
        :hint="$lang('AUTH:REGISTER:INPUT_TEXT_PASSWORD-HINT')"
        counter
      ></v-text-field>
    </v-card-text>
    <v-card-actions class="my-0 py-0">
      <v-spacer></v-spacer>
      <v-btn block class="blue accent-4" dark type="submit">
        <v-icon left>mdi-account-plus</v-icon>
        {{ $lang("Create Account") }}
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </form>
</template>

<script>
import bus from "@/bus";
import { mapActions } from "vuex";
import localforage from "@/plugins/localforage";
import { isEmpty } from "lodash";
import { axios } from "@/plugins/axios";
export default {
  name: "register-with-mobile",
  components: {},
  data() {
    return {
      user: {
        username: "",
        mobile: "",
        password: "",
      },
      showSettings: false,
      showPassword: false,
      rules: {
        required: (value) => !!value || this.$lang("Required"),
        min: (v) => {
          return (
            v.length >= 6 ||
            this.$lang("AUTH:REGISTER:INPUT_TEXT_PASSWORD-HINT")
          );
        },
        max: (v) => {
          return v.length == 10 || this.$lang("MobileNumberRule");
        },
      },
    };
  },
  computed: {
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  methods: {
    ...mapActions({
      register: "auth/registerMobile",
    }),
    doRegister() {
      if (!this.user.mobile || this.user.mobile.length != 10) {
        this.$store.dispatch("setAlert", {
          message: this.$lang("MobileNumberRule"),
          type: "red white--text",
        });
        return;
      }
      this.register({ user: this.user })
        .then((res) => {
          this.afterSuccess();
        })
        .catch(() => {});
    },
    afterSuccess(verified) {
      console.log("success");
      return localforage
        .getItem("intended")
        .then((intended) => {
          if (isEmpty(intended)) {
            this.$router.replace({ name: "home" });
            return;
          }

          if (verified) {
            //remove this key if user is varified
            localforage.removeItem("intended");
          }

          this.$router.replace(intended);
        })
        .catch((err) => {
          console.error(err);
          this.$router.replace({ name: "home" });
        });
    },
  },
  mounted() {},
  created() {
    console.log(this.$route.meta);
    bus.$on("contextButtonClicked", () => {
      this.showSettings = !this.showSettings;
    });
  },
  destroyed() {},
};
</script>


